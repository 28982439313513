.filters .mat-form-field {
  margin-right: 10px;
}

.filters {
  align-items: center;
}

.filters button {
  height: 40px;
  margin-right: 10px;
}

.titulo_pagina {
  margin: 20px;
}

.ligacao:hover {
  cursor: pointer;

}


input[type="email"] {
  text-transform: lowercase;
}


.welldoneIcon {
  font-size: 16px;
  color: rgb(0, 223, 141)
}

.errorIcon {
  color: rgb(223, 45, 0);
  font-size: 16px;


}

.nothingIcon {
  color: transparent;
  font-size: 16px;
}

.nomeInstalacao {
  text-align: start;
}

.sessions-backfilter {

  position: absolute;
  background-color: rgba(10, 16, 21, 0.719);
  height: 100%;
  width: 100%;
}

.sessions-bgImage {
  position: absolute;
  filter: blur(15px);
  height: 100%;
  width: 100%;

}

.butoes-tabelas button {

  margin-right: 10px;
  margin-top: 10px;

}

.butoes-tabelas {
  align-items: center;
  margin: 10px;
}

.primeira-coluna-tabela {
  display: flex;
  align-items: center;
}