
.snackbar_erro {
    background-color: rgb(192, 0, 0) !important;

}
.snackbar_normal {
    background: rgb(0, 133, 18) !important;

}
.snackbar_normal span {

    color: white
}

.snackbar_erro {
    background-color: rgb(192, 0, 0) !important;

}

.snackbar_erro span {
    color: white
}

