.todo-option {
  padding: 20px 16px;
}

.tag {
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 12px;
  line-height: 24px;
  box-sizing: content-box;
  background: rgba(0, 0, 0, 0.12);

  & > div {
    display: inline-block;
  }

  .circle {
    margin-right: 5px;
    height: 8px;
    width: 8px;
    background: red;
    border-radius: 50%;
  }
}

.no-fill-color {
  color: transparent;
}
